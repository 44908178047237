import { IonButton, IonIcon } from "@ionic/react";
import { trash } from "ionicons/icons";
import { useRef } from "react";
import './present.css';

const Present = (props) => {
  const { present, onClickPresent } = props;
  const ref = useRef();
  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div className="flex-center tile" onClick={props.edit ? () => {} : onClickPresent}>
        <img className="present-picture" src={present.pictures[0]} alt=""/>
        {/* { present.reserved ? <div className={`frozen ${present.reservedByMe ? 'freeze-by-me' : ''}`}><img src="./assets/frozen.svg" alt=""/></div> : null } */}
      </div>
      { props.edit ? <div className="flex-center badge delete-badge" onClick={() => {props.onDelete(present.pk,ref.current);}}><IonIcon icon={trash} /></div> : null }
      { present.user_action === 'like' ? <div className="flex-center badge"><img src="./assets/like.svg" alt=""/></div> : null }
      { present.user_action === 'dislike' ? <div className="flex-center badge"><img src="./assets/dislike.svg" alt=""/></div> : null }
    </div>
  );
};

export default Present;
