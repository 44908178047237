import {
  createAnimation,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import Present from '../../common/components/present/present';
import "./presents.css";

import UserContext from "../../userContext";
import { useParams } from "react-router-dom";

const animationBuilder = (baseEl, opts) => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo('opacity', 0, 1)
    .duration(150);

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo('opacity', 1, 0)
    .duration(150);

  const animation = createAnimation()
    .addAnimation(enteringAnimation)
    .addAnimation(leavingAnimation);

  return animation;
}

const Presents = (props) => {
  const [presents, setPresents] = useState([]);
  const [friend, setFriend] = useState('');
  const [present] = useIonAlert();
  const { user } = useContext(UserContext);
  const [editMode, setEditMode] = useState(false);
  const router = useIonRouter();
  const params = useParams();

  useEffect(() => {
    fetch(`https://api.prezle.com/v0/presents?shortlink=${params.shortlink}`)
      .then(response => response.json())
      .then(response => {
        setPresents(response.presents);
        setFriend(response.friend);
        props.setPresentPageContent(response);
      });
  }, []);

  useEffect(() => {
    if (friend) {
      present({
        header: `Welcome to Prezle`,
        message: `These are ${friend}'s present choices. Look around and see what you want to get them!`,
        buttons: [
          { text: 'Learn more', handler: () => { window.open('https://apple.co/3ENa4au')} },
          { text: 'Got it!', handler: () => { } },
        ],
        onDidDismiss: () => { },
      });
    }
  }, [friend])

  const handleDelete = (pk, ref) => {
    ref.style.transition = "0.25s";
    ref.style.transform = "scale(0)";
    setTimeout(() => {
      setPresents(presents.filter((present) => present.pk !== pk));
      user.deletePresent(pk).then(() => {
        user.getLikedPresents().then(setPresents);
      });
    }, 200);
  };

  const title = friend ? `${friend}'s Presents` : '';
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-text-center">
            {title}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="presents-container">
          {presents.map((present) => (
            <div
              key={present.pk}
            >
              <Present
                present={present}
                edit={editMode}
                onDelete={handleDelete}
                onClickPresent={() => {
                  if (editMode) return;
                  router.push(
                    `/${params.shortlink}/presents/${present.pk}`,
                    "forward",
                    "push",
                    {},
                    animationBuilder
                  );
                }}
              />
            </div>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Presents;
