import React, { Component } from "react";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar, faStarHalfStroke } from "@fortawesome/pro-solid-svg-icons";
// import { faStar as faEmptyStar } from "@fortawesome/pro-regular-svg-icons";

import { IonIcon } from "@ionic/react";
import { star, starHalfOutline, starOutline } from 'ionicons/icons';

class StarRating extends Component {
  state = {};
  render() {
    let { rating } = this.props;
    if (!rating) return;
    rating = parseFloat(rating);
    const numStars = Math.round(rating * 2) / 2;
    const wholeStars = Math.floor(numStars);
    const halfStar = numStars !== wholeStars;
    const emptyStars = 5 - halfStar - wholeStars;
    const text =rating.toFixed(1);
    return (
      <div className="flex-center fw" style={{ padding: '0px 10px 10px 10px', backgroundColor: "white"}}>
        {[...Array(wholeStars).keys()].map((n) => (
          <IonIcon key={n} icon={star} style={{ color: "#d33343" }} />
        ))}
        {halfStar ? (
          <IonIcon icon={starHalfOutline} style={{ color: "#d33343" }} />
        ) : null}
        {[...Array(emptyStars).keys()].map((n) => (
          <IonIcon key={n} icon={starOutline} style={{ color: "#d33343" }} />
        ))}
        <h6 style={{ paddingLeft: 5, margin: 0, fontWeight: 700 }}>{text}</h6>
      </div>
    );
  }
}

export default StarRating;
