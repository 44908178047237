import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonIcon,
  IonFab,
  IonFabButton,
  IonFabList,
  useIonToast,
  useIonAlert,
} from "@ionic/react";
import "./card.css";
import PriceRating from "../priceRating";
import StarRating from "../starRating";
import { globeOutline, flash, snow, trashBin, menu, ellipsisVertical } from "ionicons/icons";
import UserContext from "../../../userContext";
import { useContext, useState } from "react";

const Card = (props) => {
  const [card, setCard] = useState(props.card);
  const { user } = useContext(UserContext);
  const [present] = useIonAlert();

  return (
    <div className="flex-center card-container">
      { !props.friendId || (!card.reserved || card.reservedByMe) ?
      <IonFab>
        <IonFabButton>
          <IonIcon icon={ellipsisVertical} />
        </IonFabButton>
        <IonFabList side="bottom">
          <div style={{ marginTop: 5, animation: "tile-appear-grow 0.3s forwards" }}>
            <IonFabButton color="secondary"
              onClick={() => {
                window.open(card.url);
              }}>
              <IonIcon
                icon={globeOutline}
                style={{ fontSize: 24 }}
              />
            </IonFabButton>
          </div>
          { props.friendId ? <div style={{ marginTop: 15, animation: "tile-appear-grow 0.3s forwards" }}>
            <IonFabButton color="warning" onClick={() => {
              const reserved = !card.reserved;
              setCard({ ...card, reserved, reservedByMe: true });
              user.reservePresent(card.pk, props.friendId);
            }}>
              <IonIcon
                icon={snow}
                style={{ fontSize: 24 }}
              />
            </IonFabButton>
          </div> : null }
          { props.friendId ? null : <div style={{ marginTop: 15, animation: "tile-appear-grow 0.3s forwards" }}>
            <IonFabButton color="warning" onClick={() => {
              present({
                header: 'Unable to Reserve in the web',
                message: 'Download the Prezle app to reserve this present and stop anyone else from buying it',
                buttons: [
                  'Cancel',
                  { text: 'Download', handler: () => { window.location.href = "https://apple.co/3ENa4au"} },
                ],
                onDidDismiss: () => {},
              })
            }}>
              <IonIcon
                icon={snow}
                style={{ fontSize: 24 }}
              />
            </IonFabButton>
          </div> }
        </IonFabList>
      </IonFab> : null }
      <IonCard className="flex-center card">
        <div className="flex-center fw image-container">
          <img src={card.pictures[0]} alt="" />
        </div>
        {card.rating ? <StarRating rating={card.rating} /> : null}
        <div style={{ flexShrink: 0, width: '100%' }}>
          <IonCardHeader>
            <div className="header">
              <IonCardSubtitle style={{ fontSize: 12 }}>
                {card.source}
              </IonCardSubtitle>
              <PriceRating price={card.price} />
            </div>
            {/* <IonCardSubtitle style={{ fontSize: 12 }}>{`${props.state}`}</IonCardSubtitle> */}
            <IonCardTitle style={{ fontSize: 15 }}>{card.name}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="content">
            <span>{card.description}</span>
          </IonCardContent>
        </div>
      </IonCard>
      {/* <div className={`freeze-cover ${card.reserved ? 'fc-active' : ''} ${card.reservedByMe ? 'freeze-by-me' : ''}`}>
        <img src="./assets/frozen.svg" style={{ height: '100%', width: '100%', flexShrink: 0 }} alt="" />
        <div style={{ height: 180 }} className="flex-center">
          <h1 style={{ color: 'white', fontSize: 54, fontFamily: 'Pacifico' }}>Reserved</h1>
        </div>
      </div> */}
    </div>
  );
};

export default Card;
