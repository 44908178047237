import { Redirect, Route } from "react-router-dom";
import {
  IonBadge,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonRouter,
} from "@ionic/react";
import { giftOutline, albumsOutline, peopleOutline, constructOutline } from "ionicons/icons";
import Presents from "./pages/presents/Presents";
import CardCarousel from "./common/views/CardCarousel";
import { useState, useEffect, useContext } from "react";
import UserContext from "./userContext";

const Main = (props) => {

  const [friendPageContent, setFriendPageContent] = useState(null);
  const [presentPageContent, setPresentPageContent] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [counts, setCounts] = useState({ inboundQueue: 0, friendRequests: 0 })
  const { user } = useContext(UserContext);
  const router = useIonRouter();

  const path = router.routeInfo.pathname;

  return (
    <IonRouterOutlet>
      <Route exact path="/:shortlink">
        <Presents setPresentPageContent={setPresentPageContent} />
      </Route>
      <Route exact path="/:shortlink/presents/:presentId">
        <CardCarousel presentPageContent={presentPageContent} />
      </Route>
    </IonRouterOutlet>
  );
};

export default Main;
