import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import Card from "../components/card/card";
import UserContext from "../../userContext";
import { useParams } from "react-router";
import { Virtual, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/effect-coverflow';
import './CardCarousel.css';

const CardCarousel = (props) => {
  const { user } = useContext(UserContext);
  const [cards, setCards] = useState([]);
  const { friendId, presentId } = useParams();
  const params = useParams();
  const [present] = useIonAlert();

  const deleteCard = (pk) => {
    setCards(cards.filter(c => c.pk !== pk));
  } 

  useEffect(() => {
    present({
      header: `Be careful, don't buy a duplicate!`,
      message: `<img class="frozen-presents" src="./assets/frozen-presents.svg" /><p class="alert-text">Before you buy this, be sure to download the Prezle app to check it hasn't already been reserved.</p>`,
      buttons: [
        { text: 'Download now', handler: () => { window.location.href = "https://apple.co/3ENa4au" } },
        { text: 'Got it!', handler: () => { } },
      ],
      onDidDismiss: () => { },
    });
    if (props.presentPageContent) {
      setCards(props.presentPageContent.presents);
      return;
    }
    fetch(`https://api.prezle.com/v0/presents?shortlink=${params.shortlink}`)
    .then(response => response.json())
    .then(response => {
      setCards(response.presents);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  var banner = null;

  if (!friendId) banner = 'Presents';

  //try cards effect for swiper again

  const cardsForSwiper = cards.filter(c => !c.sentByMe);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/${params.shortlink}`} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        {cards.length ? <Swiper
          direction={"vertical"}
          centeredSlides={true}
          slidesPerView={1.3}
          initialSlide={cards.map(card => card.pk).indexOf(presentId)}
          modules={[Virtual,EffectCoverflow]}
          effect="coverflow"
          coverflowEffect={{slideShadows: false}}
          virtual={{addSlidesAfter: 1, addSlidesBefore: 1, enabled: true}}
        >
          {cardsForSwiper.map((card) => (
            <SwiperSlide key={card.pk} virtualIndex={cardsForSwiper.indexOf(card)}>
              <Card card={card} key={card.pk} friendId={friendId} deleteCard={deleteCard} />
            </SwiperSlide>
          ))}
        </Swiper> : null}
      </IonContent>
    </IonPage>
  );
};

export default CardCarousel;
