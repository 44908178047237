import { hash, lstrip } from "./helpers";

export class Api {
  //Shoudl we await this?
  constructor(user, setUser) {
    this.user = user;
    if (user) user.getSession((err, session) => {
      this.details = session.getIdToken().payload;
      setUser(this);
    });
  }

  async getToken() {
    return await new Promise((resolve, reject) => {
      this.user.getSession((err, session) => {
        resolve(session.getIdToken().getJwtToken());
      });
    });
  }

  async request(url, paramsOrPayload = null, method = 'GET') {
    const token = await this.getToken();
    let requestUrl = `https://api.prezle.com/v1${url}?`;
    if (method === 'GET' && paramsOrPayload) requestUrl += new URLSearchParams(paramsOrPayload);
    const options = {
      method,
      headers: { Authorization: `Bearer ${token}` },
      ...(paramsOrPayload && method !== 'GET' ? { body: JSON.stringify(paramsOrPayload) } : {})
    };
    const response = await fetch(requestUrl, options);
    return await response.json();
  }

  async getCounts() {
    return await this.request('/me/counts');
  }
  
  async getPresents() {
    return await this.request('/me/queue');
    // return result
    //   .map((value) => ({ value, sort: Math.random() }))
    //   .sort((a, b) => a.sort - b.sort)
    //   .map(({ value }) => value);
  }

  async getFriendQueue(friendId) {
    return await this.request('/friends/queue',{friendId});
  }

  async swipe(presentId, swipeType) {
    return await this.request('/me/presents', { presentId, swipeType }, 'POST');
  }

  async adminSwipe(presentId, swipeType) {
    return await this.request('/admin', { presentId, swipeType }, 'POST');
  }

  async adminGetPresents() {
    return await this.request('/admin');
  }

  async swipeFriendOutbound(presentId, friendId) {
    return await this.request('/friends/presents', { presentId, friendId }, 'POST');
  }

  async getLikedPresents() {
    return await this.request('/me/presents');
  }

  async searchFriend(number) {
    const id = await hash(number);
    return await this.request('/friends/search', { id })
  }

  async addFriend(number) {
    const id = await hash(number);
    return await this.request('/friends', { id }, 'POST')
  }

  async addNewUserFriend(number,displayName) {
    const id = await hash(number);
    return await this.request('/friends', { id, displayName }, 'POST')
  }

  async confirmFriend(id) {
    return await this.request('/friends', { id }, 'PUT');
  }

  async getFriends() {
    return await this.request('/friends');
  }

  async getFriend(id) {
    return await this.request('/friends', { id });
  }

  async deleteFriend(id) {
    return await this.request('/friends', { id }, 'DELETE');
  }

  async deletePresent(pk) {
    return await this.request('/me/presents', { pk }, 'DELETE');
  }

  async getFriendPresents(friendId) {
    return await this.request('/friends/presents', { friendId });
  }

  async reservePresent(presentId, friendId) {
    return await this.request('/friends/presents', { presentId, friendId }, 'PUT');
  }
  
  async setNotificationToken(token, type) {
    return await this.request('/me/profile/notifications', { token, type }, 'POST');
  }

  async createSharingLink() {
    return await this.request('/me/presents/share',{},'POST');
  }

  async checkContacts(contacts) {
    const request = {
      contacts: await Promise.all(contacts.map(async (c) => ({
        id: c.contactId,
        numbers: await Promise.all(c.phoneNumbers.map((p) => hash(p.number)))
      })))
    };
    return await this.request("/friends/search", request, 'POST');
  }
}