import { Route } from "react-router-dom";
import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { useEffect, useState } from "react";

import Main from "./Main";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

import { Api } from "./utils/api";

/* Theme variables */
import "./theme/variables.css";
import UserContext from "./userContext";


const App = () => {
  const [user, setUser] = useState(null);
  const [refresh, setRefresh] = useState(false);

  setupIonicReact({
    swipeBackEnabled: false
  });

  useEffect(() => {
    
  }, [refresh]);


  return (
    <IonApp>
      <IonReactRouter>
        <Route path="/">
          <UserContext.Provider value={{ user, setUser }}>
          <Main />;
          </UserContext.Provider>
        </Route>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
