const PriceRating = (props) => {
  const { price } = props;
  var icon = "./assets/dollar.svg";

  var numIcons = price ?? 0;

  if (price > 500) {
    icon = "./assets/diamond.svg";
    numIcons = price / 100;
  } else if (price > 50) {
    icon = "./assets/gold.svg";
    numIcons = price / 10;
  }

  numIcons = Math.ceil(numIcons / 10);

  if (!numIcons) return null;

  return (
    <div style={{ display: "flex", gap: 2 }}>
      {[...Array(numIcons).keys()].map((n) => (
        <img src={icon} key={n} height={15} alt="" />
      ))}
    </div>
  );
};

export default PriceRating;
